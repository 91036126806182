import React, { useEffect, useState } from 'react'
import { Container, Row, Col ,Spinner} from 'react-bootstrap'
import {  MdOutlinePerson } from "react-icons/md";
import { AiFillGolden } from "react-icons/ai";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import API_DOMAIN from '../config/config';

const DashBoard = () => {
  const [userecoveryData, setUserrecoveryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jewelpawnData,setUserjewlpawnData] = useState([]);
  const [estimationdata,setUserestimationData] = useState([]);
  const[customerData,setcustomerData] = useState([]);
  const fetchDatajewelpawncustomer = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });


      const responseData = await response.json();
      setLoading(false);
      if (responseData.head.code === 200) {
       
        setcustomerData(responseData.body.customer);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  const fetchDataestimation = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/pawnestimate.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });


      const responseData = await response.json();
      setLoading(false);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.pawnjewelry_estimate.map(user => ({
          ...user,
          jewel_product: JSON.parse(user.jewel_product || '[]') // Ensure jewel_product is an array
        }));
       
        setUserestimationData(sortedData);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };

  const fetchDatarecovery = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/pawnrecovery.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });

      const responseData = await response.json();
      setLoading(false);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.pawnjewelry_recovery.map(user => ({
          ...user,
          jewel_product: JSON.parse(user.jewel_product || '[]') // Ensure jewel_product is an array
        }));

      
       setUserrecoveryData(sortedData);
        
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  const fetchDatajewelpawn = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/pawnjewelry.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });


      const responseData = await response.json();
      setLoading(false);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.pawnjewelry.map(user => ({
          ...user,
          jewel_product: JSON.parse(user.jewel_product || '[]') // Ensure jewel_product is an array
        }));

       
       setUserjewlpawnData(sortedData);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error.message);
    }
  };
  useEffect(() => {
    setLoading(true); 
    Promise.all([
    fetchDatajewelpawn(),
    fetchDatarecovery(),
    fetchDataestimation(),
    fetchDatajewelpawncustomer(),
    ]).then(()=> setLoading(false));
  }, []);
  return (
    <>
      <div class="main-content">
        <Container>
        {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          ) : (
          <Row>
            <Col lg='3' md='6' xs='12' className='py-3'>
              <div className='counter-card cyan'>
                <span><MdOutlinePerson /></span>
                <span class="count-numbers plus bold">{customerData.length}</span>
                <span class="count-name regular">வாடிக்கையாளர்</span>
              </div>
            </Col>
            <Col lg='3' md='6' xs='12' className='py-3'>
              <div className='counter-card blue'>
                <span><AiFillGolden /></span>
                <span class="count-numbers plus bold">{jewelpawnData.length}</span>
                <span class="count-name regular"> நகை அடகு</span>
              </div>
            </Col>
            <Col lg='3' md='6' xs='12' className='py-3'>
              <div className='counter-card green'>
                <span className=''>< RiDeviceRecoverLine /></span>
                <span class="count-numbers plus bold">{userecoveryData.length}</span>
                <span class="count-name regular">நகை மீட்பு</span>
              </div>
            </Col>
            <Col lg='3' md='6' xs='12' className='py-3'>
              <div className='counter-card  red'>
                <span><FaPersonCircleQuestion /></span>
                <span class="count-numbers plus bold">{estimationdata.length}</span>
                <span class="count-name regular">எஸ்டிமேட்</span>
              </div>
            </Col>
          </Row>
           )}
        </Container>
      </div>
    </>
  )
}

export default DashBoard