import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from './components/sidebar/SideBar'
import '../src/components/sidebar/sidebar.css'
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../src/pages/Login';
import User from './pages/User';
import CompanyCreation from './pages/CompanyCreation';
import { BrowserRouter } from 'react-router-dom';
import Group from './pages/Group';
import JewelPawningCreation from './pages/JewelPawningCreation';
import UserCreation from './pages/UserCreation'
import JewelRecoveryCreation from './pages/JewelRecoveryCreation '
import JewelEstimateCreation from './pages/JewelEstimateCreation';
import DashBoard from './pages/DashBoard';
import Company from './pages/Company';
import JewelPawning from './pages/JewelPawning';
import JewelRecovery from './pages/JewelRecovery';
import JewelEstimate from './pages/JewelEstimate';
import GroupCreation from './pages/GroupCreation';
import Interest from './pages/Interest';
import InterestCreation from './pages/InterestCreation'
import JewelPawnPreview from './pdf/JewelPawnPreview';
import ReportPreview from './pdf/ReportPreview';
import Customer from './pages/Customer';
import OverallReport from './pages/OverallReport';
import PawnReport from './pages/PawnReport';
import RecoveryReport from './pages/RecoveryReport';
import InterstReport from './pages/InterstReport';
import JewelPawnG from './pages/JewelPawnG';
import JewelPawngPreview from './pdf/JewelPawngPreview'
import CustomerDetails from './pages/CustomerDetails';
import PawnGReport from './pages/PawnGReport';
import NetworkStatusPopup from './networkalert';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const retryConnection = () => {
    window.location.reload();
    if (navigator.onLine) {
      setIsOnline(true);
      window.location.reload();
    }
    
  };
  return (
    <div className='App'>
      <BrowserRouter>
        <NetworkStatusPopup isOnline={isOnline} retryConnection={retryConnection} />
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/console/dashboard' element={<DashBoard />} ></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/user/create' element={<UserCreation />}></Route>
            <Route path='/console/company' element={<Company />}></Route>
            <Route path="/console/company/create" element={<CompanyCreation />}></Route>+
            <Route path='/console/pawn/jewelpawning' element={<JewelPawning />}></Route>
            <Route path='/console/pawn/jewelpawning/create' element={<JewelPawningCreation />}></Route>
            <Route path='/console/pawn/jewelpawng' element={<JewelPawnG />}></Route>
            <Route path='/console/pawn/jewelpawng/create' element={<JewelPawningCreation />}></Route>
            <Route path='/console/interest' element={<Interest />}></Route>
            <Route path='/console/interest/create' element={<InterestCreation />}></Route>
            <Route path='/console/master/jewelrecovery' element={<JewelRecovery />}></Route>
            <Route path='/console/master/jewelrecovery/create' element={<JewelRecoveryCreation />}></Route>
            <Route path='/console/master/jewelestimate' element={<JewelEstimate />}></Route>
            <Route path='/console/master/jewelestimate/create' element={<JewelEstimateCreation />}></Route>
            <Route path='/console/master/group' element={<Group />}></Route>
            <Route path='/console/master/group/create' element={<GroupCreation />}></Route>
            <Route path='/console/master/customer' element={<Customer />}></Route>
            <Route path='/console/master/customerdetails' element={<CustomerDetails />}></Route>
            <Route path='/console/report/overall' element={<OverallReport />}></Route>
            <Route path='/console/report/pawn' element={<PawnReport />}></Route>
            <Route path='/console/report/pawng' element={<PawnGReport />}></Route>
            <Route path='/console/report/recovery' element={<RecoveryReport />}></Route>
            <Route path='/console/report/interst' element={<InterstReport />}></Route>
          </Route>
          <Route path='/console/jewelpawn' element={<JewelPawnPreview />}></Route>
          <Route path='/console/jewelpawng' element={<JewelPawngPreview />}></Route>
          <Route path='/console/report' element={<ReportPreview />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;