import React,{useState,useEffect} from 'react';
import { Table,Button, Dropdown } from 'react-bootstrap';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import API_DOMAIN from '../config/config';
import { Buttons } from './Buttons';
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import JewelPawnPdf from "../pdf/JewelPawnPdf"
import JewelPawnPdfG from "../pdf/JewelPawnPdfg"
import { PDFDownloadLink } from '@react-pdf/renderer';

const TableUI = ({ headers, body, style, type, rowData, planViewAction ,pageview}) => {

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(body.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, body.length);
  const currentItems = body.slice(startIndex, endIndex);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [body]);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0-11
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;
    };

  
  const navigate = useNavigate();

  const handleEditClick = (rowData) => {
    navigate("/console/user/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleDeleteClick = async (user_id) => {
    
    try {
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_user_id: user_id
        })
      });

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        navigate("/console/user");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleCompanyEditClick = (rowData) => {
    navigate("/console/company/create", { state: { type: "edit", rowData: rowData } });
  }
 

  const handleJewelPawningEditClick = (rowData) => {
    navigate("/console/pawn/jewelpawning/create", { state: { type: "edit", rowData: rowData } });
  }

  const handleJewelPawningprintviewClick = (rowData) => {
    navigate("/console/jewelpawn", { state: { type: "pdfview", rowData: rowData } });
  }

  const handleJewelPawningDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/pawnjewelry.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_pawnjewelry_id: id
        })
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        navigate("/console/pawn/jewelpawning");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleJewelPawngEditClick = (rowData) => {
    navigate("/console/pawn/jewelpawng/create", { state: { type: "edit", rowData: rowData } });
  }

  const handleJewelPawngprintviewClick = (rowData) =>{
    navigate("/console/jewelpawng", { state: { type: "pdfview", rowData: rowData } });
  }

  const handleJewelPawngDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/pawnjewelryg.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_pawnjewelry_id: id
        })
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        navigate("/console/pawn/jewelpawng");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleinterestEditClick = (rowData) => {
    navigate("/console/interest/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleinterestDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/interest.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_interest_id: id
        })
      });

      const responseData = await response.json();



      if (responseData.head.code === 200) {
        navigate("/console/interest");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleJewelEstimateEditClick = (rowData) => {
    navigate("/console/master/jewelestimate/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelEstimateDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/pawnestimate.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_pawnjewelry_estimate_id: id
        })
      });

      const responseData = await response.json();

  
      if (responseData.head.code === 200) {
        navigate("/console/master/jewelestimate");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleJewelUnitEditClick = (rowData) => {
    navigate("/console/master/unit/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelUnitDeleteClick = async (unit_id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_unit_id: unit_id
        })
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        navigate("/console/master/unit");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleJewelGroupEditClick = (rowData) => {
    navigate("/console/master/group/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelGroupDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/group.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_Group_id: id
        })
      });

      const responseData = await response.json();

   

      if (responseData.head.code === 200) {
        navigate("/console/master/group");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleJewelcustomerViewClick = (rowData) => {
    navigate("/console/master/customerdetails", { state: { type: "view", rowData: rowData } });
  }

  const handleJewelcustomerEditClick = (rowData) => {
    navigate("/console/master/customerdetails", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelcustomerDeleteClick = async (id) => {
 
    try {
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_customer_id: id
        })
      });

      const responseData = await response.json();


      if (responseData.head.code === 200) {
        navigate("/console/master/customer");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleJewelCategoryEditClick = (rowData) => {
    navigate("/console/master/category/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelCategoryDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_category_id: id
        })
      });

      const responseData = await response.json();



      if (responseData.head.code === 200) {
        navigate("/console/master/category");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleJewelRecoveryEditClick = (rowData) => {
    navigate("/console/master/jewelrecovery/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleJewelRecoveryDeleteClick = async (id) => {

    try {
      const response = await fetch(`${API_DOMAIN}/pawnrecovery.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_pawnjewelry_recovery_id: id
        })
      });

      const responseData = await response.json();



      if (responseData.head.code === 200) {
        navigate("/console/master/jewelrecovery");
        window.location.reload();
      } else {
        console.log(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <>
    {pageview === "yes" && (
        <div className='text-end'>
          <span className='mx-1'>Page {currentPage} of {totalPages}</span>
          <span className='mx-1'>
            <Buttons lable={<MdChevronLeft />} onClick={prevPage} disabled={currentPage === 1} />
          </span>
          <span className='mx-1'>
            <Buttons lable={<MdChevronRight />} onClick={nextPage} disabled={currentPage === totalPages} />
          </span>
        </div>
      )}
    <Table responsive="md" style={style}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {currentItems.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {type === "USER" && ( // Checking if type is "USER"
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.Name}</td>
                <td>{rowData.RoleSelection}</td>
                <td>{rowData.Mobile_Number}</td>
                {/* <td>{rowData.User_Name}</td>
                <td>{rowData.Password}</td> */}
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDeleteClick(rowData.user_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "company" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.company_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.place}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "interest" && (
              <> 
                <td>{rowData.id}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.recipt_no}</td>
                <td>{rowData.mobile_number}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleinterestEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleinterestDeleteClick(rowData.interest_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelPawning" && (
             
              <> {/* Fragment shorthand */}
                {/* <td>{rowData.id}</td> */}
                <td>{formatDate(rowData.pawnjewelry_date)}</td>
                <td>{rowData.recipt_no}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.address}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleJewelPawningprintviewClick(rowData)}>print View</Dropdown.Item>
                    <PDFDownloadLink document={<JewelPawnPdf data={rowData} />} fileName={`${rowData.recipt_no}.pdf`}>
                          {({ blob, url, loading, error }) => (
                              <a className="dropdown-item" role="button" tabIndex="0" href={url} download={`${rowData.recipt_no}.pdf`}>
                                  Download Pdf
                              </a>
                          )}
                    </PDFDownloadLink>
                       
                      <Dropdown.Item onClick={() => handleJewelPawningEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelPawningDeleteClick(rowData.pawnjewelry_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelPawng" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.recipt_no}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.address}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <PDFDownloadLink document={<JewelPawnPdfG data={rowData} />} fileName={`${rowData.recipt_no}.pdf`}>
                          {({ blob, url, loading, error }) => (
                              <a className="dropdown-item" role="button" tabIndex="0" href={url} download={`${rowData.recipt_no}.pdf`}>
                                  Download Pdf
                              </a>
                          )}
                    </PDFDownloadLink>
                       <Dropdown.Item onClick={() => handleJewelPawngprintviewClick(rowData)}>print View</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelPawngEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelPawngDeleteClick(rowData.pawnjewelryg_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelRecovery" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.recipt_no}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.address}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleJewelRecoveryViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleJewelRecoveryEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelRecoveryDeleteClick(rowData.pawnjewelry_recovery_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelEstimate" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.recipt_no}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.address}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleJewelEstimateViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleJewelEstimateEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelEstimateDeleteClick(rowData.pawnjewelry_estimate_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelUnit" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.unit_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleJewelUnitViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleJewelUnitEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelUnitDeleteClick(rowData.unit_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelGroup" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.Group_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleJewelGroupViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleJewelGroupEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelGroupDeleteClick(rowData.Group_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "jewelCategory" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.Group_type}</td>
                <td>{rowData.Category_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={() => handleJewelCategoryViewClick(rowData)}>View</Dropdown.Item> */}
                      <Dropdown.Item onClick={() => handleJewelCategoryEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelCategoryDeleteClick(rowData.category_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "customer" && ( 
              <>
                <td>{rowData.id}</td>
                <td>{rowData.customer_name}</td>
                <td>{rowData.mobile_number}</td>
                <td>{rowData.address}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleJewelcustomerViewClick(rowData)}>View</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelcustomerEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleJewelcustomerDeleteClick(rowData.customer_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
    {pageview === "yes" && (
        <div className='text-end'>
          <span className='mx-1'>Page {currentPage} of {totalPages}</span>
          <span className='mx-1'>
            <Buttons lable={<MdChevronLeft />} onClick={prevPage} disabled={currentPage === 1} />
          </span>
          <span className='mx-1'>
            <Buttons lable={<MdChevronRight />} onClick={nextPage} disabled={currentPage === totalPages} />
          </span>
        </div>
      )}
    </>
  );
};

export default TableUI;
