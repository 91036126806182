import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Alert, Modal } from 'react-bootstrap';
import { TextInputForm, DropDownUI, Calender } from '../components/Forms';
import { ClickButton } from '../../src/components/ClickButton';
import PageNav from '../components/PageNav';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_DOMAIN from '../config/config';



const parseJewelProduct = (productString) => {
    try {
        return JSON.parse(productString);
    } catch (error) {
        console.error('Error parsing jewel_product:', error);
        return [{ JewelName: '', weight: '', count: '' }];
    }
};

const UserCreation = () => {

    const location = useLocation();
    const { type, rowData } = location.state || {};
    const today = new Date();
    const defaultDate = today.toISOString().substr(0, 10);
   
    const initialState = type === 'edit' ? {
        ...rowData,
        jewel_product: rowData.jewel_product ? parseJewelProduct(rowData.jewel_product) : [{ JewelName: '', weight: '', count: '' }],
        remark_interest : rowData.interest_remark,
        interest_recive_date: rowData.interest_recive_date ? new Date(rowData.interest_recive_date) : defaultDate,
    } : {
        interest_id: '',
        pawnjewelry_id: '',
        recipt_no: '',
        customer_name: '',
        name_of_guardians: '',
        mobile_number: '',
        address: '',
        group: '',
        jewel_original_rate: '',
        pawn_rate: '',
        pawn_interest: '',
        pawn_interest_amount: '',
        interest_recive_date: defaultDate,
        extra_amount: '',
        remark_interest: '',
        jewel_product: [{ JewelName: '', weight: '', count: '' }]
    };
    const [formData, setFormData] = useState(initialState);
    console.log('formData', formData);
    const [grupData, setgrupData] = useState([]);
    const [error, setError] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
     if(formData.recipt_no === ''){
        setFormData({
        interest_id: '',
        pawnjewelry_id: '',
        customer_name: '',
        name_of_guardians: '',
        mobile_number: '',
        address: '',
        group: '',
        jewel_original_rate: '',
        pawn_rate: '',
        pawn_interest: '',
        pawn_interest_amount: '',
        interest_recive_date: defaultDate,
        extra_amount: '',
        remark_interest: '',
        jewel_product: [{ JewelName: '', weight: '', count: ''}]
        });
     }
    }, [formData])
    
    useEffect(() => {
        const fetchgroup = async () => {
            try {
                const response = await fetch(`${API_DOMAIN}/group.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        search_text: ''
                    })
                });
    
    
                const responseData = await response.json();
                console.log('responseData', responseData)
                setLoading(false);
                if (responseData.head.code === 200) {
                    let sortedData = responseData.body.group;
    
                    setgrupData(Array.isArray(sortedData) ? sortedData : [sortedData]);
                } else {
                    throw new Error(responseData.head.msg);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error.message);
            }
        };
        fetchgroup();
    }, [])
    
    useEffect(() => {
       
        if (formData.recipt_no) {
            fetchDatapawnjewelry(formData.recipt_no);
        }
    }, [formData.recipt_no]);

    const redirectModal = () => {
        navigate('/console/interest');
    };

    const handleChange = (e, fieldName, rowIndex) => {
        const value = e.target ? e.target.value : e.value;

        let updatedFormData = { ...formData };

        if (rowIndex !== undefined) {
            // If rowIndex is defined, it means we are updating a table row
            updatedFormData = {
                ...formData,
                jewel_product: formData.jewel_product.map((row, index) =>
                    index === rowIndex ? { ...row, [fieldName]: value } : row
                )
            };
        } else {
            // If rowIndex is undefined, update the top-level formData
            updatedFormData = {
                ...formData,
                [fieldName]: value
            };
        }

        setFormData({
            ...updatedFormData,
            [fieldName]: value
        });
    };
    const handleAddRow = () => {
        setFormData({
            ...formData,
            jewel_product: [...formData.jewel_product, { JewelName: '', weight: '', count: '' }]
        });
    };

    const handleDeleteRow = (index) => {
        if (formData.jewel_product.length === 1) {
            return;
        }
        const updatedRows = formData.jewel_product.filter((row, rowIndex) => rowIndex !== index);
        setFormData({
            ...formData,
            jewel_product: updatedRows
        });
    };

    const setLabel = (value, label) => {
        // Update formData with new date value
        setFormData(prevState => ({
          ...prevState,
          [label]: value
        }));
      }
    
    const fetchDatapawnjewelry = async (receiptNo) => {
        try {
          const response = await fetch(`${API_DOMAIN}/pawnjewelry.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              search_text: receiptNo
            })
          });
    
    
          const responseData = await response.json();
          console.log('responseDatapawn',responseData)
          setLoading(false);
          if (responseData.head.code === 200) {
            let sortedData = responseData.body.pawnjewelry.map(user => ({
              ...user,
              jewel_product: JSON.parse(user.jewel_product || '[]') // Ensure jewel_product is an array
            }));
            if (sortedData.length > 0) {
                console.log('sortedData',sortedData);
                setFormData({
                    ...formData,
                    pawnjewelry_id: sortedData[0].pawnjewelry_id,
                    customer_name: sortedData[0].customer_name,
                    name_of_guardians: sortedData[0].name_of_guardians,
                    mobile_number: sortedData[0].mobile_number,
                    address: sortedData[0].address,
                    group: sortedData[0].group,
                    jewel_original_rate: sortedData[0].jewel_original_rate,
                    pawn_rate: sortedData[0].pawn_rate,
                    pawn_interest: sortedData[0].pawn_interest,
                    pawn_interest_amount: sortedData[0].pawn_interest_amount,
                    jewel_product: sortedData[0].jewel_product
                });
            }
    
          } else {
          alert('Receipt number does not match the fetched data.');
          // Optionally clear formData or set default values
          setFormData({
            interest_id: '',
            pawnjewelry_id: '',
            customer_name: '',
            name_of_guardians: '',
            mobile_number: '',
            address: '',
            group: '',
            jewel_original_rate: '',
            pawn_rate: '',
            pawn_interest: '',
            pawn_interest_amount: '',
            interest_recive_date: defaultDate,
            extra_amount: '',
            remark_interest: '',
            jewel_product: [{ JewelName: '', weight: '', count: ''}]
           
          });
            throw new Error(responseData.head.msg);
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error.message);
        }
      };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log('keyPressed');
            handleAddRow();
        }
    }
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        try {
            const response = await fetch(`${API_DOMAIN}/interest.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    interest_id : '',
                    pawnjewelry_id: formData.pawnjewelry_id,
                    recipt_no : formData.recipt_no,
                    jewel_product : formData.jewel_product,
                    customer_name : formData.customer_name,
                    name_of_guardians : formData.name_of_guardians,
                    mobile_number : formData.mobile_number,
                    address : formData.address,
                    group_id : formData.group,
                    jewel_original_rate : formData.jewel_original_rate,
                    pawn_rate : formData.pawn_rate,
                    pawn_interest : formData.pawn_interest,
                    pawn_interest_amount :formData.pawn_interest_amount,
                    extra_amount : formData.extra_amount,
                    interest_recive_date : formData.interest_recive_date,
                    remark_interest : formData.remark_interest
                })
            });
            
            const responseData = await response.json();

            console.log(responseData);

            if (responseData.head.code === 200) {
                toast.success(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    navigate("/console/interest");
                }, 1000)

            } else {
                toast.error(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateSubmit = async () => {

        setLoading(true);


        try {
            const response = await fetch(`${API_DOMAIN}/interest.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    interest_id : rowData.interest_id,
                    pawnjewelry_id: formData.pawnjewelry_id !== '' ? formData.pawnjewelry_id : rowData.pawnjewelry_id,
                    recipt_no : formData.recipt_no !== '' ? formData.recipt_no : rowData.recipt_no,
                    jewel_product : formData.jewel_product !== '' ? formData.jewel_product : rowData.jewel_product,
                    customer_name : formData.customer_name !== '' ? formData.customer_name : rowData.customer_name,
                    name_of_guardians : formData.name_of_guardians !== '' ? formData.name_of_guardians : rowData.name_of_guardians, 
                    mobile_number : formData.mobile_number !== '' ? formData.mobile_number : rowData.mobile_number,
                    address : formData.address !== '' ? formData.address : rowData.address,
                    group_id : formData.group!== '' ? formData.group : rowData.group,
                    jewel_original_rate : formData.jewel_original_rate !== '' ?formData.jewel_original_rate : rowData.jewel_original_rate,
                    pawn_rate : formData.pawn_rate !== '' ? formData.pawn_rate : rowData.pawn_rate,
                    pawn_interest : formData.pawn_interest !== '' ?formData.pawn_interest :rowData.pawn_interest,
                    pawn_interest_amount :formData.pawn_interest_amount !== '' ? formData.pawn_interest_amount : rowData.pawn_interest_amount,
                    extra_amount : formData.extra_amount !== '' ? formData.extra_amount : rowData.extra_amount,
                    interest_recive_date : formData.interest_recive_date !== '' ? formData.interest_recive_date : rowData.interest_recive_date,

                    remark_interest : formData.remark_interest !== '' ?  formData.remark_interest : rowData.interest_remark,

                }),
            });

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.head.code === 200) {
                toast.success(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                setTimeout(() => {
                    navigate("/console/interest");
                }, 2000);
            } else {
                toast.error(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error(responseData.message || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.message);
        }

        setLoading(false);
    };
    return (
        <div className='main-content'>
            <Container>
                <Row className='regular'>
                    <Col lg='12' md='12' xs='12' className='py-3'>
                        <PageNav pagetitle={`வட்டி ${type === 'view' ? ' வியூ ' : type === 'edit' ? '  எடிட் ' : 'உருவாக்கம்'}`}></PageNav>
                    </Col>
                    <Col lg='4' md='6' xs="12" className='py-3'>
                        <Calender setLabel={(date) => setLabel(date, 'interest_recive_date')}  initialDate={type === 'edit' ? formData.interest_recive_date : undefined} calenderlabel="தேதி" disabled={type === 'view' || type === 'edit'} />
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'ரசீது எண்'}
                            labelname={'ரசீது எண்'}
                            name='recipt_no'
                            value={formData.recipt_no}
                            onChange={(e) => handleChange(e, 'recipt_no')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'வாடிக்கையாளர் பெயர்'}
                            labelname={'வாடிக்கையாளர் பெயர்'}
                            name='customer_name'
                            value={formData.customer_name}
                            onChange={(e) => handleChange(e, 'customer_name')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'தந்தை அல்லது கணவர் பெயர்'}
                            labelname={'தந்தை அல்லது கணவர் பெயர்'}
                            name='name_of_guardians'
                            value={formData.name_of_guardians}
                            onChange={(e) => handleChange(e, 'name_of_guardians')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'கைபேசி எண்'}
                            labelname={'கைபேசி எண்'}
                            name='mobile_number'
                            value={formData.mobile_number}
                            onChange={(e) => handleChange(e, 'mobile_number')}
                        />
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'முகவரி'}
                            labelname={'முகவரி'}
                            name='address'
                            value={formData.address}
                            onChange={(e) => handleChange(e, 'address')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <DropDownUI
                            optionlist={grupData.map(user => ({
                                value: user.Group_id,
                                label: user.Group_type
                            }))}
                            placeholder='குரூப்'
                            labelname='குரூப்'
                            name='group'
                            value={formData.group}
                            onChange={(updatedFormData) => setFormData({ ...formData, group: updatedFormData.group })}

                        />
                    </Col>


                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'நகை அசல் மதிப்பு'}
                            labelname={'நகை அசல் மதிப்பு'}
                            name='jewel_original_rate'
                            value={formData.jewel_original_rate}
                            onChange={(e) => handleChange(e, 'jewel_original_rate')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'அடகு விலை'}
                            labelname={'அடகு விலை'}
                            name='Pawning Price'
                            value={formData.pawn_rate}
                            onChange={(e) => handleChange(e, 'pawn_rate')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'வட்டி விகிதம்'}
                            labelname={'வட்டி விகிதம்'}
                            name='pawn_interest'
                            value={formData.pawn_interest}
                            onChange={(e) => handleChange(e, 'pawn_interest')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs='12' className='py-3'>
                        <TextInputForm
                            placeholder={'வட்டி தொகை'}
                            labelname={'வட்டி தொகை'}
                            name='pawn_interest_amount'
                            value={formData.pawn_interest_amount}
                            onChange={(e) => handleChange(e, 'pawn_interest_amount')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs="12" className='py-3'>
                        <TextInputForm
                            placeholder={'மேல் தொகை'}
                            labelname={'மேல் தொகை'}
                            name='extra_amount'
                            value={formData.extra_amount}
                            onChange={(e) => handleChange(e, 'extra_amount')}
                        ></TextInputForm>
                    </Col>
                    <Col lg='4' md='6' xs="12" className='py-3'>
                        <label>Remark</label>
                        <div>
                            <textarea
                                className='form-cntrl w-100'
                                placeholder='Remark'
                                value={formData.remark_interest}
                                onChange={(e) => handleChange(e, 'remark_interest')}
                            ></textarea>
                        </div>
                    </Col>
                    <Col lg='12' md='6' xs='12'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>S.no</th>
                                    <th>நகை பெயர்</th>
                                    <th>எடை</th>
                                    <th>எண்ணிக்கை</th>
                                    <th></th>
                                    <th>நீக்கு</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.jewel_product && formData.jewel_product.length > 0 && formData.jewel_product.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <TextInputForm
                                                placeholder={'நகை பெயர்'}
                                                value={row.JewelName}
                                                onChange={(e) => handleChange(e, 'JewelName', index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInputForm
                                                placeholder={'எண்ணிக்கை'}
                                                value={row.count}
                                                onChange={(e) => handleChange(e, 'count', index)}
                                                onKeyDown={(e) => handleKeyPress(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <TextInputForm
                                                placeholder={'எடை'}
                                                value={row.weight}
                                                onChange={(e) => handleChange(e, 'weight', index)}
                                                onKeyDown={handleKeyPress}
                                            />
                                        </td>
                                        <td>
                                            {index === formData.jewel_product.length - 1 && (
                                                <ClickButton label={<>Add More</>} onClick={handleAddRow} />
                                            )}
                                        </td>
                                        <td>
                                            <button onClick={() => handleDeleteRow(index)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </Col>
                    <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                        <div className='text-center'>
                            {type === "view" ? (
                                <ClickButton label={<>back</>} onClick={() => navigate("/console/interest")}></ClickButton>
                            ) : (
                                <>
                                    {type === "edit" ? (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={2000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/interest")}></ClickButton>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={2000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                                            </span>
                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/interest")}></ClickButton>
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                {error && <Alert variant='danger' className='error-alert'>{error}</Alert>}
            </Container>
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Body className='text-center'>
                    <img src={require('../components/sidebar/images/output-onlinegiftools.gif')} alt='Success GIF' />
                    <p>User saved successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <ClickButton variant='secondary' label={<> Close</>} onClick={() => redirectModal()}>
                        Close
                    </ClickButton>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default UserCreation;