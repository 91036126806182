import React,{useEffect,useState} from 'react'
import { Container, Col, Row,Form } from 'react-bootstrap'
import { FaMagnifyingGlass } from "react-icons/fa6";
import MobileView from '../components/MobileView'
import TableUI from '../components/Table';
import { TextInputForm } from '../components/Forms';
import Pagnation from '../components/Pagnation';
import { ClickButton } from '../components/ClickButton';
import API_DOMAIN from '../config/config';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
const CustomerTablehead = ["No", "Customer Name", "Mobile No.","Place", "Action"]

const Customer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const[customerData,setcustomerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const fetchDatajewelpawncustomer = async () => {
        try {
          const response = await fetch(`${API_DOMAIN}/customer.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              search_text: searchText
            })
          });
    
    
          const responseData = await response.json();
          setLoading(false);
          if (responseData.head.code === 200) {
           
            setcustomerData(responseData.body.customer);
          } else {
            throw new Error(responseData.head.msg);
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data:', error.message);
        }
      };
      useEffect(() => {
        fetchDatajewelpawncustomer();
       
    
      }, [searchText]);
      const handleSearch = (value) => {
        setSearchText(value);
    };
    const navigate = useNavigate();
    return (
        <div className='main-content'>
            <Container fluid>
                <Row>
                    <Col lg='7' md='4' xs='6'>
                        <div className='page-nav py-3'>
                            <span class="nav-list">Customer</span>
                        </div>
                    </Col>
                    <Col lg='5' md='3' xs='6' className='align-self-center text-end'>
                        <ClickButton label={<>PDF</>}></ClickButton>
                    </Col>
                    <Col lg='3' md='5' xs='12' className='py-1'>
                    <TextInputForm
                            placeholder={"பெயர் ,மொபைல் எண்."}
                            onChange={(e) => handleSearch(e.target.value)}
                            prefix_icon={<FaMagnifyingGlass />}> </TextInputForm>
                    
                    </Col>
                    <Col lg={9} md={12} xs={12} className='py-2'>
                        
                    </Col>
                    <Col lg='12' md='12' xs='12' className='px-0'>
                        <div className='py-1'>
                        {isMobile && customerData.map((user, index) => (
                                <MobileView key={index} sno={user.id} name={user.customer_name} subname={user.mobile_number} />
                            ))}
                            <TableUI headers={CustomerTablehead} body={customerData} type="customer" pageview={"yes"} />
                        </div>
                    </Col>
                    <Col lg={12} md={12} xs={12}>
                       
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Customer