import React from 'react'
import { Container,Row,Col, Table } from 'react-bootstrap'
import { ClickButton } from '../components/ClickButton'
import { Calender, TextInputForm } from '../components/Forms'
const PawnGReport = () => {
  return (
    <>
    <div className='main-content'>
        <Container fluid>
            <Row>
                <Col lg="6" md="6" className='py-3 align-self-center'>
                    <div> Pawn - G  Report</div>
                </Col>
                <Col lg="6" md="6" className='py-3'>
                    <div className='text-end'><ClickButton label={<>PDF</>} /></div>
                </Col>
                <Col lg="4" className='py-3'>
                    <TextInputForm labelname={<>Search</>}/>
                </Col>
                <Col lg="4" className='py-3'>
                    <Calender calenderlabel={<>From</>}/>
                </Col>
                <Col lg="4" className='py-3'>
                    <Calender calenderlabel={<>To</>}/>
                </Col>
                <Col lg="12" className='py-3'>
                    <div className='wire-table'>
                        <Table>
                            <thead>
                                <td> Date</td>
                                <td> Receipt No.</td>
                                <td> Customer Details</td>
                                <td> Amount</td>
                                <td> Interest</td>
                                <td> Product Details</td>
                                <td> Count</td>
                                <td>Weight</td>
                                <td> Total Amount</td>
                                <td> Recovery Date</td>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                        <td>   </td>
                                    </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    </>
  )
}

export default PawnGReport