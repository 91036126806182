import React, { useState } from 'react';
import { Col, Container, Row, Alert, Modal } from 'react-bootstrap';
import { TextInputForm, DropDownUI } from '../components/Forms';
import { ClickButton } from '../../src/components/ClickButton';
import PageNav from '../components/PageNav';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import API_DOMAIN from '../config/config';
import 'react-toastify/dist/ReactToastify.css';
const DropList = [
    {
        value: 'அட்மின்',
        label: 'அட்மின்'
    },
    {
        value: 'சூப்பர் அட்மின்',
        label: 'சூப்பர் அட்மின்'
    },
    {
        value: 'பணியாளர்',
        label: 'பணியாளர்'
    }
];
const GroupCreation = () => {
   
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const initialState = type === 'edit' ? { ...rowData } : {
        Group_type: '',
      };
    const [formData, setFormData] = useState(initialState);

    const [error, setError] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const navigate = useNavigate();

    const redirectModal = () => {
        navigate('/console/master/group');
    };

    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    };

    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async () => {
        for (const key in formData) {
            if (formData[key] === '') {
                toast.error(`${key} cannot be empty!`, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
        }
        try {
            const response = await fetch(`${API_DOMAIN}/group.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const responseData = await response.json();
            console.log('responseData',responseData);

            if (responseData.head.code === 200) {
                toast.success(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    navigate("/console/master/group");
                }, 1000)

            } else {
                toast.error(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateSubmit = async () => {
        setLoading(true);


        try {
            const response = await fetch(`${API_DOMAIN}/group.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    edit_Group_type: rowData.Group_id, // Include the company ID in the request
                    Group_type: formData.Group_type,
                }),
            });

            const responseData = await response.json();

            if (responseData.head.code === 200) {
                toast.success(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                // Navigate to the user list page after a delay
                setTimeout(() => {
                    navigate("/console/master/group");
                }, 2000);
            } else {
                toast.error(responseData.head.msg, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error(responseData.message || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.message);
        }

        setLoading(false);
    };

    return (
        <div className='main-content'>
            <Container>
                <Row className='regular justify-content-center'>
                    <Col lg='12' md='12' xs='12' className='py-3'>
                        <PageNav pagetitle={`குரூப்${type === 'view' ? ' வியூ ' : type === 'edit' ? '  எடிட் ' : 'உருவாக்கம்'}`}></PageNav>
                    </Col>

                    <Col lg='4' md='6' xs='12' className='py-3'>
                        {type === 'edit' ? (
                            <TextInputForm
                                placeholder={'குரூப் பெயர்'}
                                labelname={'குரூப் பெயர்'}
                                name='Group_type'
                                value={formData.Group_type}
                                onChange={(e) => handleChange(e, 'Group_type')}
                            ></TextInputForm>
                        ) : (
                            <TextInputForm
                                placeholder={'குரூப் பெயர்'}
                                labelname={'குரூப் பெயர்'}
                                name='Group_type'
                                value={type === "view" ? rowData.Group_type : formData.Group_type}
                                onChange={(e) => handleChange(e, 'Group_type')}
                            ></TextInputForm>
                        )}
                    </Col>
                    <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                        <div className='text-center'>
                            {type === "view" ? (
                                <ClickButton label={<>back</>} onClick={() => navigate("/console/user")}></ClickButton>
                            ) : (
                                <>
                                    {type === "edit" ? (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={2000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/master/group")}></ClickButton>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={2000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="colored"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                                            </span>
                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                {error && <Alert variant='danger' className='error-alert'>{error}</Alert>}
            </Container>
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
                <Modal.Body className='text-center'>
                    <img src={require('../components/sidebar/images/output-onlinegiftools.gif')} alt='Success GIF' />
                    <p>User saved successfully!</p>
                </Modal.Body>
                <Modal.Footer>
                    <ClickButton variant='secondary' label={<> Close</>} onClick={() => redirectModal()}>
                        Close
                    </ClickButton>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default GroupCreation;